<template>
  <!--begin::Advance Table Widget 2-->
  <div v-if="isReady">
    <div class="card card-custom card-stretch gutter-b">
      <!--begin::Header-->
      <div class="card-header border-0 pt-5">
        <h3 class="card-title align-items-start flex-column">
          <span class="card-label font-weight-bolder text-dark">
            {{ $t('Contact_us.list') }}
          </span>
        </h3>

        <div class="card-toolbar">
          <ul class="nav nav-pills nav-pills-sm nav-dark-75">
            <li>
              <b-button variant="outline-primary" size="sm" v-b-modal.downloading @click="download()">Export</b-button>
            </li>
          </ul>
        </div>
      </div>
      <!--end::Header-->
      <!--begin::Body-->
      <div class="card-body pt-3 pb-0">
        <!--begin::Table-->
        <div class="table-responsive">
          <table class="table table-borderless table-vertical-center" v-if="dataToShow">
            <thead>
              <tr>
                <th class="p-0" style="width: 20%">
                  {{ $t('Contact_us.name') }}
                </th>
                <th class="p-0" style="width: 20%">
                  {{ $t('Contact_us.subject') }}
                </th>
                <th class="p-0" style="width: 35%">
                  {{ $t('Contact_us.body') }}
                </th>
                <th class="p-0" style="min-width: 10%">
                  {{ $t('Contact_us.last_update') }}
                </th>
                <th class="p-0 text-right" style="min-width: 10%">
                  {{ $t('GENERAL.option') }}
                </th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in dataToShow">
                <tr v-bind:key="i">
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.name }}
                    </a>
                  </td>
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.subject ? item.subject : '-' | str_limit(128) }}
                    </a>
                  </td>
                  <td class="pl-0">
                    <a class="text-dark-75">
                      {{ item.body ? item.body : '-' | str_limit(128) }}
                    </a>
                  </td>

                  <td class="pl-0">
                    <div>
                      <a class="text-muted font-weight-bold">
                        {{ item.lu_datetime }}
                      </a>
                    </div>
                  </td>
                  <td class="text-right pr-0">
                    <a class="btn btn-icon btn-light btn-sm" @click="deleteConfirmationModal(item.id)" ref="btnShow">
                      <span class="svg-icon svg-icon-md svg-icon-danger">
                        <!--begin::Svg Icon-->
                        <inline-svg src="media/svg/icons/General/Trash.svg"></inline-svg>
                        <!--end::Svg Icon-->
                      </span>
                    </a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
          <div class="text-center" v-else>No Data</div>
        </div>
        <!--end::Table-->
      </div>
      <!--end::Body-->
      <!--end::Advance Table Widget 2-->
    </div>
    <b-modal
      id="downloading"
      ref="wait-modal"
      hide-footer
      size="sm"
      :title="isDownloading ? 'Downloading' : 'Completed'"
      no-stacking
      @ok="cancelDownload"
    >
      <div class="text-center">
        <div v-if="isDownloading">
          <p>Please wait...</p>
          <b-spinner variant="primary" label="Text Centered"></b-spinner>
          <p>
            <b-button class="mt-3" variant="outline-danger" @click="cancelDownload">Cancel</b-button>
          </p>
        </div>
        <div v-else>
          <p>Plaese download by use link below.</p>
          <a :href="downloadLink">Link</a>
        </div>
      </div>
    </b-modal>
  </div>

  <div class="card card-custom card-stretch gutter-b" v-else>
    <!--begin::Body-->
    <div class="card-body pt-3 p-20">
      <div class="d-flex justify-content-center mb-3">
        <b-spinner label="Loading..."></b-spinner>
      </div>
    </div>
  </div>
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import {LIST, QUERY, DELETE_POST} from '../services.js';

export default {
  name: 'ListTable',
  data() {
    return {
      isReady: false,
      total_post: 0,
      dataToShow: [],
      boxTwo: '',
      stillDownalod: false,
      isDownloading: false,
      downloadLink: '',
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'Contact Us', route: 'contact_us'}]);
    this.$store
      .dispatch(QUERY, {format: true})
      // go to which page after successfully login
      .then((res) => {
        this.isReady = true;
        this.dataToShow = res.data;
        this.total_post = res.data.length;
      })
      .catch((error) => {
        // console.log(error);
      });
  },
  components: {},
  methods: {
    cancelDownload() {
      this.stillDownalod = false;
      this.$refs['wait-modal'].hide();
    },
    download() {
      this.isDownloading = true;
      this.stillDownalod = true;
      setTimeout(() => {
        if (this.stillDownalod) {
          this.isDownloading = false;
          this.downloadLink = '#';
        }
      }, 3000);
    },
    deleteConfirmationModal(id) {
      this.boxTwo = '';
      this.$bvModal
        .msgBoxConfirm('Are you sure to delete this post?', {
          title: 'Please Confirm',
          size: 'sm',
          buttonSize: 'sm',
          okVariant: 'danger',
          okTitle: 'Confirm',
          cancelTitle: 'Cancel',
          footerClass: 'p-2',
          hideHeaderClose: false,
          centered: true,
        })
        .then((value) => {
          if (value) {
            this.isReady = false;
            // console.log('Deleting post confirmation: ' + id);
            this.$store
              .dispatch(DELETE_POST, {id: id})
              // go to which page after successfully login
              .then((res) => {
                this.$store
                  .dispatch(QUERY, {format: true})
                  // go to which page after successfully login
                  .then((res) => {
                    this.isReady = true;
                    this.dataToShow = res.data;
                    this.total_post = res.data.length;
                  })
                  .catch((error) => {
                    // console.log(error);
                  });
              })
              .catch((error) => {
                // console.log(error);
              });
          }
        })
        .catch((err) => {});
    },
    deleteConfirm() {},
  },
  computed: {},
};
</script>
